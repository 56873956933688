
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        







































































































































































.my-meter-invoices,
[class*='my-meter-invoice--'] {
  padding-top: 0;
}

.my-meter-invoices__row__ref {
  white-space: nowrap;
}

.my-meter-invoices__row__status {
  display: block;
  text-align: center;
  white-space: nowrap;

  svg {
    fill: $c-green;
    vertical-align: top;
  }
}

.my-meter-invoices__link {
  cursor: pointer;
}

.my-meter-invoices__row__download {
  display: block;
  text-align: center !important; /* stylelint-disable-line declaration-no-important */

  span {
    @extend %visually-hidden;
  }

  svg {
    fill: $c-orange;
  }
}

.table-outer {
  @include scrollbars(3px, $c-orange-light, lighten($c-orange-light, 40%));

  overflow-y: auto;
}

.table {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.table__th {
  @extend %fw-normal;
  // prettier-ignore
  @include fluid(font-size, (xxs: 9px, m: 11px));

  padding: 0 0.5rem 2rem;
  color: $c-orange;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.table__td {
  padding: 1.5rem 0.5rem;

  tr:not(:last-child) & {
    border-bottom: 1px solid $c-gray-light;
  }
}
